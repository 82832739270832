<template>
  <div class='list-picker'>

    <input type='hidden' :name="name" :value='JSON.stringify({"list_ids": value})''>

    <multiselect :options="options"
                  label="name" track-by="id" :multiple='multiple_options' :closeOnSelect='!multiple_options' v-model='selected'></multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  props: ["options", "required", "name", "prevalue", "multiple_options"],
  components: {
    Multiselect
  },
  mounted () {
    var that = this;
  },
  mounted () {
  },
  watch: {
    selected: function (values) {
      var that = this;

      if (this.multiple_options) {
        var returnArray = [];
        values.forEach((element) => {
          returnArray.push(element.id)
        });
        that.value = returnArray
      } else {
        var returnArray = [];
        returnArray.push(values.id)
        that.value = returnArray
      }
    },
  },
  data: function () {
    return {
      value: this.prevalue ? this.prevalue : [],
      selected: this.prevalue ? this.prevalue : [],
    }
  }
}
</script>

